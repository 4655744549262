import React, {Component} from 'react';
import SectionTitleTwo from '../../component/Banner/SectionTitleTwo';
import Slider from 'react-slick';
import '../progress.scss';


class PortfolioSlider extends Component {
    render(){
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false,
            autoplay: true,
            pauseOnHover:true,
            autoplaySpeed: 5000,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 991,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
        };
        return(
            <section className="portfolio_slider_area" id="portfolio">
                <div className="container">
                    <SectionTitleTwo tCenter="text-center" stitle="My Portfolio" btitle="Some of my latest works on Github"/>
                    <Slider {...settings} className="portfolio_slider">
                        <div className="p_item">
                            <div className="portfolio_content">
                                <a target="_blank" rel="noopener noreferrer" href="https://github.com/desdamo/HomeMade-kanban"><img src={require('../../image/kanban.png')} alt=""/></a>
                                <div className="text">
                                    <a target="_blank" rel="noopener noreferrer" href="https://github.com/desdamo/HomeMade-kanban"><h4>HomeMade Kanban</h4></a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://github.com/desdamo/HomeMade-kanban">Full JS</a>
                                </div>
                            </div>
                        </div>
                        <div className="p_item">
                            <div className="portfolio_content">
                                <a target="_blank" rel="noopener noreferrer" href="https://github.com/desdamo/React_GitHub_search"><img src={require('../../image/seo.png')} alt=""/></a>
                                <div className="text">
                                    <a target="_blank" rel="noopener noreferrer" href="https://github.com/desdamo/React_GitHub_search"><h4>Github Search Module</h4></a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://github.com/desdamo/React_GitHub_search">React & API</a>
                                </div>
                            </div>
                        </div>
                        <div className="p_item">
                            <div className="portfolio_content">
                                <a target="_blank" rel="noopener noreferrer" href="https://github.com/desdamo/Currency-Converter-React"><img src={require('../../image/currencies.png')} alt=""/></a>
                                <div className="text">
                                    <a target="_blank" rel="noopener noreferrer" href="https://github.com/desdamo/Currency-Converter-React"><h4>Currencies rate finder</h4></a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://github.com/desdamo/Currency-Converter-React">Full React</a>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
        )
    }
}

export default PortfolioSlider;