import React, { Component } from "react";
import Reveal from "react-reveal/Reveal/";
import SectionTitleTwo from "../component/Banner/SectionTitleTwo";
import MyForm from "./MyForm";

class ContactTwo extends Component {
  render() {
    return (
      <section className="contact-area contact-area-two bg_color" id="contacts">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div className="get_info">
                <SectionTitleTwo
                  stitle="Get in Touch"
                  btitle="have coffee?"
                />
                <div className="media get_item">
                  <i className="flaticon-phone"></i>
                  <div className="media-body">
                    <a href=".#">+33(0)625 08 10 72</a>
                  </div>
                </div>
                <div className="media get_item">
                  <i className="flaticon-chat"></i>
                  <div className="media-body">
                    <a href=".#">dr.loic@gmail.com</a>
                  </div>
                </div>
                <div className="media get_item">
                  <i className="flaticon-pin"></i>
                  <div className="media-body">
                    <p>Evian - FR</p>
                  </div>
                </div>
              
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <Reveal effect="fadeInRight" duration={800}>
                <div className="input_form">
                  <SectionTitleTwo
                    stitle="Contact Me"
                  />
                  <MyForm/>
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactTwo;
