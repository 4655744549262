import ProgressBar from 'react-bootstrap/ProgressBar';
import React, { Component } from 'react';
import SectionTitleTwo from '../component/Banner/SectionTitleTwo';
import './progress.scss';


class Progress extends Component {
    render() {

        return (
            <div className="clients_logo_area_two bg_color">
                <div className="c_round"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="clients_content_two">
                                <SectionTitleTwo stitle="Langages" btitle="Because we need " />
                                <SectionTitleTwo  btitle="Technical and soft skills, " />
                                <SectionTitleTwo  btitle="Discover mine" />

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="skill_content_two">
                                <SectionTitleTwo stitle="Technical Skills" />
                                <ProgressBar animated now={80} variant="success" label="HTML & CSS" />
                                <ProgressBar animated now={65} variant="info" label="React & JS" />
                                <ProgressBar animated now={75} variant="danger" label="Node JS & Express" />
                                <ProgressBar animated now={70} variant="success" label="Redux" />
                                <ProgressBar animated now={65} variant="info" label="Git" />
                                <ProgressBar animated now={65} variant="danger" label="Gatsby JS" />

                            </div>
                            <div className="skill_content_two row2">
                                <SectionTitleTwo stitle="Soft Skills" />
                                <ProgressBar animated now={100} variant="success" label="Team Spirit" />
                                <ProgressBar animated now={85} variant="info" label="Creativity" />
                                <ProgressBar animated now={90} variant="danger" label="Communication" />
                                <ProgressBar animated now={95} variant="success" label="Stress Management" />
                                <ProgressBar animated now={100} variant="info" label="Respect" />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}
export default Progress;


