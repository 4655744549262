const ldrData = {
    "name": "Loïc DAVID-ROGEAT",
    "role": "Fullstack JS Developer,",
    "ptext": "Based To Evian-Les-Bains.",
    "socialLinks":[
        {
          "name": "Linkedin",
          "url": "https://www.linkedin.com/in/lo%C3%AFc-david-rogeat-6612b673/",
          "className": "social_linkedin"
        },
       
        
      ],
      "aboutme": "About Me",
      "aboutdetails": "I'm Loïc DAVID-ROGEAT Fullstack JS Developper",
      "service":[
          {
            "id" : 1,
            "iconName": "icon-desktop",
            "serviceTitle": "Web Design",
            "sDetails" : "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text."
          },
          {
            "id" : 2,
            "iconName": "icon-pencil",
            "serviceTitle": "Development",
            "sDetails" : "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text."
          },
          {
            "id" : 3,
            "iconName": "icon-presentation",
            "serviceTitle": "Marketing",
            "sDetails" : "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text."
          },
          {
            "id" : 4,
            "iconName": "icon-linegraph",
            "serviceTitle": "Strategy",
            "sDetails" : "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text."
          },
          {
            "id" : 5,
            "iconName": "icon-gears",
            "serviceTitle": "Support",
            "sDetails" : "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text."
          },
          {
            "id" : 6,
            "iconName": "icon-tools",
            "serviceTitle": "We’re experienced",
            "sDetails" : "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text."
          }
      ],
      "contact":[
        {
          "id": "1",
          "title": "Contact Info",
          menuItems: [
            {
              id: 1,
              icon: 'icon_pin_alt',
              text: 'Address: Evian-Les-Bains',
            },
            {
              id: 2,
              icon: 'icon_phone',
              text: 'Phone: +33 6 25 08 10 72',
            },
            {
              id: 3,
              icon: 'icon_mail',
              text: 'Email: dr.loic@gmail.com',
            },
            {
              id: 4,
              icon: 'icon_globe',
              text: 'Website: http://www.loicdr.fr',
            }
          ]
        },
      ],
    contacttitle: 'Contact Form',
}

export default ldrData;

