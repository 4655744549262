import React from 'react';
import NavbarTwo from './component/Navbar/NavbarTwo';
import FBanner from './component/Banner/frelencerBanner';
import PortfolioSlider from './component/Portfolio/PortfolioSlider';
import ServiceTwo from './component/ServiceTwo';
import Progress from './component/Progress';
import ClientslogoTwo from './component/ClientslogoTwo';
import ContactTwo from './component/ContactTwo';
import FooterTwo from './component/FooterTwo';
import ldrData from './component/ldrData';

export const Homefrelencer = () => (
  <div className="body_wrapper">
    <NavbarTwo mClass="menu_two" mainlogo="logo-blue.png" stickylogo="logo-blue.png"/>  
    <FBanner ldrData={ldrData}/>
    <ClientslogoTwo/>
    <Progress/>
    <ServiceTwo rowFlex="flex-row-reverse"/>
    <PortfolioSlider/>
    <ContactTwo jhonData={ldrData}/>
    <FooterTwo jhonData={ldrData}/>
  </div>
)
