import React, {Component} from 'react';
import SectionTitleTwo from '../component/Banner/SectionTitleTwo';
class ClientslogoTwo extends Component {
    render(){
        return(
            <div className="clients_logo_area_two bg_color">
                <div className="c_round"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="clients_content_two">
                                <SectionTitleTwo stitle="Langages" btitle="Find here my Programming languages"/>
                                <p>Because i'm JS Fullstack developper, i work with Back & Front technologies</p>
                                <div className="clients_item">
                                    <h2>JS</h2>
                                </div>
                                <div className="clients_item">
                                    <h2>FULLSTACK</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="clients_intrigration">
                                <a href=".#" className="c_items"><img src={require('../image/redux.png')} alt=""/></a>
                                <a href=".#" className="c_items"><img src={require('../image/babel.png')} alt=""/></a>
                                <a href=".#" className="c_items"><img src={require('../image/nodejs.png')} alt=""/></a>
                                <a href=".#" className="c_items middle"><img src={require('../image/react.png')} alt=""/></a>
                                <a href=".#" className="c_items"><img src={require('../image/css3.webp')} alt=""/></a>
                                <a href=".#" className="c_items"><img src={require('../image/javascript.png')} alt=""/></a>
                                <a href=".#" className="c_items"><img src={require('../image/github.png')} alt=""/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ClientslogoTwo;